import {
    playerId,
    currentConnection,
    disconnect,
    setPlayerId
} from "@/services/WebSocket";
import {getLeaderboard, leaveGameHandler, updateScoreHandler} from "@/services/GameServices";

export let numberThrows;
export let isCampen = false;

export async function eventHandler(eventData) {
    const messageBody = eventData.messageBody;
    switch (eventData.message) {
        case 'updatePlayer':
            updatePlayedPlayer(messageBody.playedPlayerId, messageBody.name, messageBody.score, messageBody.numberThrows);
            if (messageBody.newKnight) {
                switchKnight(messageBody.playedPlayerId, messageBody.oldKnightId);
            }
            return {
                throwsLeft: numberThrows,
                modalContent: messageBody.modalContent,
            }
        case 'joinPlayer':
            const player = {
                id: eventData.joinedPlayerId,
                name: eventData.name,
                score: eventData.score,
                isTurn: false
            }
            addPlayer(player);
            break;
        case 'leavePlayer':
            removePlayer({
                id: messageBody.playerId,
            })
            if (messageBody.nextPlayerId) {
                document.getElementById(`${messageBody.nextPlayerId}`).innerHTML = `<b>${messageBody.nextPlayerName}</b>: 0`;
                rollButton(!(messageBody.nextPlayerId === playerId));
            }
            break;
        case 'newRound':
            resetLeaderBoard(messageBody.nextPlayerId, messageBody.players);
            isCampen = false;
            numberThrows = 3;
            if (messageBody.newKnight) {
                switchKnight(messageBody.playedPlayerId, messageBody.oldKnightId);
            }
            return {
                throwsLeft: numberThrows,
                modalContent: messageBody.modalContent,
            }
        case 'is31':
            rollButton(false);
            return {
                showSendSipModal: true,
                players: messageBody.players,
                throwsLeft: numberThrows
            }
        case 'receivedSip' :
            return {
                throwsLeft: numberThrows,
                modalContent: messageBody.modalContent,
            }
        case 'isCampen':
            isCampen = true;
            messageBody.campPlayers.forEach(campPlayer => updateCampPlayers(campPlayer, messageBody.nextPlayerId));
            if (messageBody.newKnight) {
                switchKnight(messageBody.playedPlayerId, messageBody.oldKnightId);
            }
            if (messageBody.playedPlayerId) {
                updatePlayedPlayer(messageBody.playedPlayerId, messageBody.name, messageBody.score, messageBody.numberThrows)
            }
            return {
                throwsLeft: numberThrows,
                modalContent: messageBody.modalContent,
            }
        case 'nextTurn':
            handleNextTurn(messageBody.nextPlayerId, messageBody.nextPlayerName);
            if (messageBody.restPlayersNumberThrows && messageBody.playedPlayerId !== playerId) {
                numberThrows = messageBody.restPlayersNumberThrows;
            }
            updatePlayedPlayer(messageBody.playedPlayerId, messageBody.name, messageBody.score, messageBody.numberThrows);
            if (messageBody.newKnight) {
                switchKnight(messageBody.playedPlayerId, messageBody.oldKnightId);
            }
            return {
                throwsLeft: numberThrows,
                modalContent: messageBody.modalContent,
            }
    }
}

function addPlayer(player){
    const knightList = document.getElementById('knightList');
    const knightItem = document.createElement('li');
    knightItem.innerHTML = `<img src="/default.png">`;
    if (player.knight) {
        knightItem.innerHTML = `<img src="/knight.png">`;
    }
    knightItem.id = `knight-${player.id}`;
    knightList.appendChild(knightItem);

    const list = document.getElementById('leaderboard');
    const li = document.createElement('li');
    li.innerHTML = `${player.name}: ${player.score}`;
    if (player.isTurn) {
        li.innerHTML = `<b>${player.name}</b>: ${player.score}`
    }
    li.id = `${player.id}`;
    list.appendChild(li);
}

function handleNextTurn(nextPlayerId, nextPlayerName){
    document.getElementById(`${nextPlayerId}`).innerHTML = `<b>${nextPlayerName}</b>: 0`
    rollButton(!(nextPlayerId === playerId));
}

function updatePlayedPlayer(playedPlayerId, playedPlayerName, playedPlayerScore, throws){
    if (throws > 0) {
        document.getElementById(`${playedPlayerId}`).innerHTML = `<b>${playedPlayerName}</b>: ${playedPlayerScore}`;
    } else {
        document.getElementById(`${playedPlayerId}`).innerHTML = `${playedPlayerName}: ${playedPlayerScore}`;
    }
    if (playedPlayerId === playerId) {
        numberThrows = throws;
        rollButton(throws === 0);
    }
}

function updateCampPlayers(player, nextPlayerId){
    if (player.id === nextPlayerId) {
        document.getElementById(`${player.id}`).innerHTML = `<b>${player.name}</b>: ${player.score}`
    } else {
        document.getElementById(`${player.id}`).innerHTML = `${player.name}: ${player.score}`
    }
    rollButton(nextPlayerId !== playerId)
    if (player.id === playerId) {
        numberThrows = 1;
    }
}

function removePlayer(player){
    const leaderboard = document.getElementById('leaderboard');
    leaderboard.removeChild(document.getElementById(`${player.id}`))

    const knightList = document.getElementById('knightList');
    knightList.removeChild(document.getElementById(`knight-${player.id}`))
}

function resetLeaderBoard(nextPlayerId, players) {
    players.map(player => {
        player.score = 0;
        numberThrows = 3;
        if (player.id === nextPlayerId) {
            document.getElementById(`${player.id}`).innerHTML = `<b>${player.name}</b>: ${player.score}`
        } else {
            document.getElementById(`${player.id}`).innerHTML = `${player.name}: ${player.score}`
        }
    });

    rollButton(nextPlayerId !== playerId);
}

export async function leaveGame(paramGameId, hardLeave){
    const response = await leaveGameHandler(localStorage.getItem('playerId'), paramGameId, hardLeave);
    if (response.statusCode === 400) {
        return response.message;
    }

    resetLeaderboard();

    await disconnect(currentConnection).then(
        () => {
            setPlayerId(undefined);
        }
    ).catch (() => {})
    return false;
}

export async function updateScore(paramGameId, score, dices) {
    const response = await updateScoreHandler(paramGameId, score, playerId, dices);
    return {
        loading: false,
        canHoldFirstDice: response.canHoldFirstDice,
        canHoldSecondDice: response.canHoldSecondDice,
    };
}

function rollButton(status) {
    document.getElementById('rollButton').disabled = status
}

function switchKnight(newKnightId, oldKnightId) {
    document.getElementById(`knight-${newKnightId}`).innerHTML = `<img src="/knight.png">`
    if (oldKnightId) {
        document.getElementById(`knight-${oldKnightId}`).innerHTML = `<img src="/default.png">`
    }
}

export async function setLeaderboard(gameId) {
    const response = await getLeaderboard(gameId);
    if (playerId === ''){
        await setPlayerId(response.leaderboard[response.leaderboard.length - 1].id);
    }
    response.leaderboard.forEach(p => {
        let player = {
            id: p.id,
            name: p.name,
            score: p.score,
            isTurn: p.isTurn,
            knight: p.knight,
        }
        addPlayer(player);
        if (p.id === playerId) {
            numberThrows = p.numberThrows
            rollButton(p.isTurn !== true);
        }
    });

    return numberThrows;
}

export function resetLeaderboard() {
    if (document.getElementById('knightList')) {
        document.getElementById('knightList').innerHTML = '';
        document.getElementById('leaderboard').innerHTML = '';
    }
}
