<template>
<div class='above'>
</div>
<div class='message'>
  <div class='content'>
    <h1>Enter game pin</h1>
    <input type="text" placeholder="Game Pin" id="pinInput" style="text-transform:uppercase"/>
    <p id="errorMessageJoin"></p>
    <button class="button" @click='joinGame()'>
      <pulse-loader :loading=loading :color=color :size=size></pulse-loader>
      <span v-if=!loading class="button__text">Join game</span>
    </button>
  </div>
</div>
<div class='landscape'>
  <div class='center'>
    <h1>Please turn</h1>
    <h1>your phone</h1>
  </div>
</div>
<div class='desktop'>
  <div class='center'>
    <h1>Only available</h1>
    <h1>on mobile</h1>
  </div>
</div>
<div class='under'>
</div>
</template>

<script>
import { findGame, setGamePin } from "../services/GameServices";
import { connect } from '../services/WebSocket';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'JoinView',
  components: {
    PulseLoader
  },
  data() {
    return {
      loading: false,
      color: '#ffffff',
      size: '15px'
    }
  },
  methods: {
    // Connect & find game to check if it exists and if the lobby is full 
    async joinGame(){
      this.loading = true;
      await connect()
      let pin = document.getElementById('pinInput').value.toUpperCase();
      const response = await findGame(pin);
      if (response.statusCode === 400) {
        document.getElementById('errorMessageJoin').style.display = 'block';
        document.getElementById('errorMessageJoin').innerText = 'Game lobby is full.';
      } else if (response.statusCode === 404) {
        document.getElementById('errorMessageJoin').style.display = 'block';
        document.getElementById('errorMessageJoin').innerText = `Game lobby doesn't exist`
      } else if (response.statusCode === 200){
        await setGamePin(pin, response.game.input.id);
        document.getElementById('errorMessageJoin').style.display = 'none';
        document.getElementById('pinInput').value = null;
        this.$router.push({name: 'PlayerName', params: {paramGameId: response.game.input.id}})
      }
      this.loading = false;
    },
  },
}
</script>

<style>
  .message input {
    background: #f4f4f4;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 15px 0px;
    font-size: 20px;
    border: none;
    text-align: center;
    min-width: 320px;
    min-height: 60px;
  }
  p {
    color: white;
  }
  #errorMessageJoin {
    display: none;
  }
</style>
