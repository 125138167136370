<template>
<div class='above'>
</div>
<div class='message'>
  <h1>Enter player name</h1>
  <input type="text" placeholder="Player Name" id="nameInput"/>
  <p id="errorMessagePlayerName"></p>
  <button class="button" id="joinButton" @click='joinGame()'>
    <pulse-loader :loading=loading :color=color :size=size></pulse-loader>
    <span v-if=!loading class="button__text">Let's play!</span>
  </button>
</div>
<div class='landscape'>
  <div class='center'>
    <h1>Please turn</h1>
    <h1>your phone</h1>
  </div>
</div>
<div class='desktop'>
  <div class='center'>
    <h1>Only available</h1>
    <h1>on mobile</h1>
  </div>
</div>
<div class='under'>
</div>
</template>

<script>
import { currentConnection, sendMessage, setPlayerId } from '../services/WebSocket';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  props: ['paramGameId'],
  name: 'PlayerNameView',
  components: {
    PulseLoader
  },
  data() {
    return {
      connection: null,
      connectionId: '',
      players: [],
      loading: false,
      color: '#ffffff',
      size: '15px'
    }
  },
  methods: {
    async joinGame(){
      let name = document.getElementById("nameInput").value;
      if (name.length > 8) {
        document.getElementById('errorMessagePlayerName').style.display = 'block';
        document.getElementById('errorMessagePlayerName').innerText = `Player name can't be longer than 8 characters`;
        return;
      }
      if (name.length === 0) {
        document.getElementById('errorMessagePlayerName').style.display = 'block';
        document.getElementById('errorMessagePlayerName').innerText = `Player name can't be empty`;
        return;
      }
      this.loading = true;
      const payload = {
        action: 'join',
        playerName: name,
        gameId: this.paramGameId
      }
      await sendMessage(payload);
      document.getElementById('joinButton').disabled = true
      document.getElementById('errorMessagePlayerName').style.display = 'none';
      currentConnection.onmessage = (event) => {
        let eventData = JSON.parse(`${event.data}`);
        setPlayerId(eventData.joinedPlayerId);
        document.getElementById("nameInput").value = null;
        if (eventData.message === 'joinPlayer' && eventData.name === name) {
          this.$router.push({name: 'Game', params: {paramGameId: this.paramGameId}})
          document.getElementById('joinButton').disabled = false
          this.loading = false;
        }
      }
    },
  },
}
</script>

<style>
#errorMessagePlayerName {
  display: none;
}
</style>
