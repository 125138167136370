<template>
  <div class="gameView">
    <Teleport to="body">
      <SendSipModelView
          :showSendSipModal="showSendSipModal"
          :players="players"
          @close="showSendSipModal = false">
        <template #header>
        </template>
      </SendSipModelView>
    </Teleport>
    <Teleport to="body">
      <ModalView
          :showModal="showModal"
          :isLeave = "isLeave"
          :leaveMessage = "leaveMessage"
          :headline = "headline"
          :message = "message"
          :subMessage = "subMessage"
          :rejoin = "rejoin"
          @close="showModal = false">
        <template #header>
        </template>
      </ModalView>
    </Teleport>
    <div class='header'>
      <button class='leaveButton' @click='leaveGameButton()'>
        <pulse-loader :loading=loadingLeave :color=color :size=sizeLeaveSpinner></pulse-loader>
        <span v-if=!loadingLeave class='button__text'>Leave</span>
      </button>
      <h2 class="throwsLeftText">Throws left {{throwsLeft}}</h2>
      <div class='scoreAndLeaderBoard'>
        <p class='playerScore'>{{ score }}</p>
        <ul class='knightList' id='knightList'>
        </ul>
        <ul class='leaderboard' id='leaderboard'>
          <pulse-loader :loading=loadingLeaderboard :color=color :size=size></pulse-loader>
        </ul>
      </div>
    </div>
    <div class='dice-wrapper'>
      <div></div>
      <div :class='getDice1' id="firstDice" @click='holdFirstDice' v-bind:style='this.diceOnePlayState'></div>
      <div :class='getDice2' id="secondDice" @click='holdSecondDice' v-bind:style='this.diceTwoPlayState'></div>
    </div>
    <div class='bottomContent'>
      <button id='rollButton' class='button' @click='setDice'>
        <pulse-loader :loading=loadingRoll :color=color :size=size></pulse-loader>
        <span v-if=!loadingRoll class='button__text'>Roll the dice!</span>
      </button>
    <footer>
      <h2>Pin: {{ gamePin }}</h2>
    </footer>
    </div>
  </div>
  <div class="bigScreenView">
    <div class='above'>
    </div>
    <div class='landscape'>
      <div class='center'>
        <h1>Please turn</h1>
        <h1>your phone</h1>
      </div>
    </div>
    <div class='desktop'>
      <div class='center'>
        <h1>Only available</h1>
        <h1>on mobile</h1>
      </div>
    </div>
    <div class='under'>
    </div>
  </div>
</template>

<script>
import '../assets/dice.css';
import {disconnect, currentConnection, rejoin, connect} from '@/services/WebSocket'
import {
  eventHandler,
  leaveGame,
  numberThrows,
  updateScore,
  setLeaderboard,
  resetLeaderboard
} from "@/services/GameViewService";
import ModalView from "@/components/ModalView";
import SendSipModelView from "@/components/SendSipModelView";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  props: ['paramGameId'],
  name: 'GameView',
  components: {
    ModalView,
    SendSipModelView,
    PulseLoader
  },
  data() {
    return {
      dice1Num: 1,
      dice2Num: 1,
      score: 0,
      gamePin: localStorage.getItem('gamePin'),
      showModal: false,
      players: [],
      throwsLeft: 3,
      isLeave: false,
      leaveMessage: '',
      showSendSipModal: false,
      loadingLeaderboard: true,
      loadingLeave: false,
      loadingRoll: false,
      color: '#ffffff',
      size: '12px',
      sizeLeaveSpinner: '8px',
      marginSpinner: '150% 2px 2px 2px',
      diceOnePlayState: {
        animationPlayState: 'paused',
        borderColor: 'transparent',
      },
      diceTwoPlayState: {
        animationPlayState: 'paused',
        borderColor: 'transparent',
      },
      heldFirstDice: false,
      heldSecondDice: false,
      headline: '',
      message: '',
      subMessage: '',
      rejoin: false,
    };
  },
  methods: {
    onMessage() {
      currentConnection.onmessage = async (event) => {
        const eventData = JSON.parse(`${event.data}`);
        const modalData = await eventHandler(eventData);
        if (modalData?.modalContent) {
          this.showModal = true;
          this.headline = modalData.modalContent.headline;
          this.message = modalData.modalContent.message;
          this.subMessage = modalData.modalContent.subMessage;
          this.isLeave = false;
        }
        if (modalData?.showSendSipModal) {
          this.showSendSipModal = modalData.showSendSipModal;
          this.players = modalData.players;
        }
        if (modalData?.throwsLeft >= 0) {
          this.throwsLeft = modalData.throwsLeft;
        }
      }
    },
    setRandomDiceData() {
      return Math.floor(Math.random() * 6) + 1;
    },
    async setDice() {
      if (!this.heldFirstDice) this.blinkFirstDice(false);
      if (!this.heldSecondDice) this.blinkSecondDice(false);
      document.getElementById('rollButton').disabled = true;
      let count = 6;
      const durationTimer = setInterval(async () => {
        this.dice1Num = !this.heldFirstDice ? this.setRandomDiceData() : this.dice1Num;
        this.dice2Num = !this.heldSecondDice ? this.setRandomDiceData() : this.dice2Num;
        this.setScore(this.dice1Num, this.dice2Num);
        if (count === 0) {
          clearInterval(durationTimer);
          await this.updatePlayerScore();
        }
        count--;
      }, 150);
    },
    setScore(dic1, dic2) {
      if (dic1 > dic2) {
        this.score = `${dic1}${dic2}`;
      }
      if (dic1 < dic2) {
        this.score = `${dic2}${dic1}`;
      }
      if (dic1 === dic2) {
        this.score = `${dic1}00`;
      }
    },
    async updatePlayerScore() {
      this.loadingRoll = true;
      const dices = [
        {
          diceNumber: 1,
          canHoldDice: this.dice1Num < 3,
          heldDice: this.heldFirstDice,
        },
        {
          diceNumber: 2,
          canHoldDice: this.dice2Num < 3,
          heldDice: this.heldSecondDice,
        }
      ];
      let updateScoreResponse = {};
      if ((numberThrows === 1 && this.score !== '31') || this.score === '32' || this.score === '21') {
        let count = 0;
        const timer = setInterval(async () => {
          if (count === 200) {
            clearInterval(timer);
            updateScoreResponse = await updateScore(
                this.paramGameId,
                Number(this.score),
                dices
            );
            this.loadingRoll = updateScoreResponse.loading;
            this.dice1Num = 1;
            this.dice2Num = 1;
            this.score = 0;
          }
          count++;
        });
      } else {
        updateScoreResponse = await updateScore(this.paramGameId, Number(this.score), dices);
        this.loadingRoll = updateScoreResponse.loading;
      }
      this.blinkFirstDice(updateScoreResponse.canHoldFirstDice);
      this.blinkSecondDice(updateScoreResponse.canHoldSecondDice);
      this.heldFirstDice = false;
      this.heldSecondDice = false;
    },
    async leaveGameButton(){
      this.loadingLeave = true;
      const message = await leaveGame(this.paramGameId, false);
      if (message) {
        this.showModal = true;
        this.isLeave = true;
        this.leaveMessage = message
        this.showSendSipModal = false;
        this.receivedSip = false;
        this.isCampen = false;
      } else {
        document.removeEventListener('visibilitychange', await this.handleVisibilityChange);
        this.$router.go(-3)
      }
      this.loadingLeave = false;
    },
    holdFirstDice() {
      if (this.diceOnePlayState.animationPlayState === 'running' && !this.heldSecondDice) {
        this.diceOnePlayState = {
          animationPlayState: 'paused',
          animation: 'none',
          borderColor: '#f44336',
        }
        this.heldFirstDice = true;
      }
    },
    blinkFirstDice(state) {
      if (state) {
        this.diceOnePlayState = {
          animationPlayState: 'running',
          borderColor: '#f44336',
        }
      } else {
        // stop blinking
          this.diceOnePlayState = {
            animationPlayState: 'paused',
            animation: 'none',
            borderColor: 'transparent',
          }
      }
    },
    holdSecondDice() {
      if (this.diceTwoPlayState.animationPlayState === 'running' && !this.heldFirstDice) {
        this.diceTwoPlayState = {
          animationPlayState: 'paused',
          animation: 'none',
          borderColor: '#f44336',
        }
        this.heldSecondDice = true;
      }
    },
    blinkSecondDice(state) {
      if (state) {
        this.diceTwoPlayState = {
          animationPlayState: 'running',
          borderColor: '#f44336',
        }
      } else {
        // stop blinking
          this.diceTwoPlayState = {
            animationPlayState: 'paused',
            animation: 'none',
            borderColor: 'transparent',
          }
      }
    },
    async handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        await disconnect(currentConnection);
        localStorage.setItem('needToRejoin', 'true');
        resetLeaderboard();
      }
      if (document.visibilityState === 'visible' && localStorage.getItem('needToRejoin') === 'true') {
        this.showModal = true;
        this.rejoin = true;
        const connection = await connect();
        connection.onopen = async () => {
          this.throwsLeft = await rejoin(this.paramGameId);
          this.showModal = false;
          this.rejoin = false;
          localStorage.removeItem('needToRejoin');
          this.onMessage();
        }
      }
    }
  },
  computed: {
    getDice1() {
      return `dice dice-${this.dice1Num}`;
    },
    getDice2() {
      return `dice dice-${this.dice2Num}`;
    },
  },
  async mounted() {
    await setLeaderboard(this.paramGameId).then(response => {
      this.throwsLeft = response;
      this.loadingLeaderboard = false;
    });
    this.onMessage();
    document.addEventListener('visibilitychange', await this.handleVisibilityChange)
  },
  async beforeRouteLeave(to) {
    if (to.name === 'PlayerName') {
      await leaveGame(this.paramGameId, true)
      document.removeEventListener('visibilitychange', await this.handleVisibilityChange);
      this.$router.go(-2);
    }
  }
};
</script>

<style>
.dice-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 35%;
}
.header {
  max-height: 35%;
}
.header h2 {
  color: white;
  margin-bottom: 0;
}
.scoreAndLeaderBoard {
  display: flex;
  min-width: 100%;
  max-height: 70%;
}
.playerScore {
  flex: 16;
  font-size: 11vh;
  color: white;
  margin: auto;
}
.leaderboard {
  flex: 8;
  color: white;
  list-style: none;
  font-size: 18px;
  margin: auto;
  padding: 0;
}
.knightList {
  flex: 2;
  color: white;
  list-style: none;
  font-size: 18px;
  margin: auto;
  padding: 0;
}
#firstDice,
#secondDice {
  border-radius: 4.5vh;
  border-width: 4.5px;
  border-style: solid;
  animation: blink 1s;
  animation-iteration-count: infinite;
}
.bottomContent {
  display: grid;
  max-height: 30%;
  min-width: 100%;
  flex-direction: column;
}
footer {
  display: grid;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
}
footer > h2 {
  justify-content: center;
  align-content: center;
  color: white;
  margin: 0;
  padding-top: 0;
  height: 100%
}
.leaveButton {
  float: left;
  margin: 20px 0 0 20px;
  max-width: 80px;
  font-size: 15px;
  background: #808080;
}
.leaveButton:hover {
  background: rgb(109, 108, 108);
}
.throwsLeftText {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  max-width: 133px;
  padding: 0 0 0 0;
}
#rollButton {
  margin: auto;
}
img {
  max-height: 25px;
  float: right;
}
@keyframes blink { 50% { border-color:#fff ; }  }
</style>
