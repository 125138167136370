import { createRouter, createWebHistory, START_LOCATION } from 'vue-router';
import { leaveGame } from '@/services/GameViewService';
import HomeView from '../views/HomeView.vue';
import GameView from '../views/GameView.vue';
import JoinView from '../views/JoinView.vue';
import HostView from '../views/HostView.vue';
import PlayerNameView from '../views/PlayerName.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/game/:paramGameId?',
    name: 'Game',
    component: GameView,
    props: true
  },
  {
    path: '/join',
    name: 'Join',
    component: JoinView
  },
  {
    path: '/host',
    name: 'Host',
    component: HostView
  },
  {
    path: '/setname/:paramGameId?',
    name: 'PlayerName',
    component: PlayerNameView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  if (to.name === 'Game' && !localStorage.getItem('gamePin')) {
    return '/';
  }
  if (from === START_LOCATION && to.name === 'Game') {
    await leaveGame(localStorage.getItem('gameId'), true)
    document.removeEventListener('visibilitychange', () => {})
    return '/';
  }
});

export default router
