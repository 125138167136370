<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <router-view/>
</template>

<style>
body {
  background: #35654d;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
html, body, div {
  margin: 0;
  height: 100%;
}
.above, .under {
  height: 30%
}
</style>
