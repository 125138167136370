<template>
  <body>
  <div class='above'>
  </div>
  <div class='message'>
    <h2>Welcome to <br> Online Mexen</h2>
    <button class="button" @click='joinGame'>
      <span class="button__text">Join a game</span>
    </button>
    <button class="button" @click='createGame'>
      <span class="button__text">Create a game</span>
    </button>
  </div>
  <div class='landscape'>
    <div class='center'>
      <h1>Please turn</h1>
      <h1>your phone</h1>
    </div>
  </div>
  <div class='desktop'>
    <div class='center'>
      <h1>Only available</h1>
      <h1>on mobile</h1>
    </div>
  </div>
  <div class='under'>
  </div>
  </body>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    joinGame() {
      this.$router.push({name: 'Join'})
    },
    createGame() {
      this.$router.push({name: 'Host'})
    }
  },
  mounted() {
    localStorage.removeItem('gamePin');
    localStorage.removeItem('playerId');
    localStorage.removeItem('gameId');
  }
}
</script>

<style>
#app {
  height: 100%;
}
html, body {
  margin: 0;
  height: 100%;
}
.message {
  display: flex;
  align-items: center;
  flex-direction: column;
  visibility: visible;
  height: 40%;
}
.message h2 {
  color: white;
  font-size: 4vh;
  margin: 1vh;
}
h1 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 200%;
  color: white;
  text-align: center;
}
.bigScreenView,
.landscape,
.desktop {
  display: none;
}
@media only screen and (min-width: 935px) {
  .bigScreenView {
    display: block
  }
  .desktop {
    display: block;
    height: 40%;
  }
  .message {
    display: none;
  }
  .gameView {
    display: none;
  }
}
@media only screen and (orientation: landscape) and (max-width: 935px) {
  .bigScreenView {
    display: block
  }
  .landscape {
    display: block;
    height: 40%;
  }
  .message {
    display: none;
  }
  .gameView {
    display: none;
  }
}
.center {
  margin: auto;
  width: 60%;
  padding: 10px;
}
.button {
  position: relative;
  min-width: 320px;
  font-size: 20px;
  background: #808080;
  max-height: 65px;
  margin: 15px auto;
}
.button:hover {
  background: rgb(109, 108, 108);
}
.button__text {
  color: #ffffff;
  transition: all 0.2s;
}
button {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  cursor: pointer;
  color: #444;
  border: none;
}
</style>
