<template>
<div class='above'>
</div>
<div class='message'>
  <h1 v-if=loadingGame>Creating game...</h1>
  <pulse-loader :loading=loadingGame :color=color :size=size :style="{'margin-top': '8%'}"></pulse-loader>
  <h1 v-if=!loadingGame>Game created</h1>
  <h1 v-if=!loadingGame>Pin: {{ gamePin }}</h1>
  <button class="button" @click='joinGame()' :disabled=loadingGame>
    <pulse-loader :loading=loadingJoin :color=color :size=size></pulse-loader>
    <span v-if=!loadingJoin class="button__text">Join</span>
  </button>
</div>
<div class='landscape'>
  <div class='center'>
    <h1>Please turn</h1>
    <h1>your phone</h1>
  </div>
</div>
<div class='desktop'>
  <div class='center'>
    <h1>Only available</h1>
    <h1>on mobile</h1>
  </div>
</div>
<div class='under'>
</div>
</template>

<script>
import { createGamePin, setGamePin } from "../services/GameServices";
import { connect } from '../services/WebSocket';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'HostView',
  components: {
    PulseLoader
  },
  data() {
    return {
      gamePin: localStorage.getItem('gamePin'),
      gameId: '',
      loadingGame: false,
      loadingJoin: false,
      color: '#ffffff',
      size: '15px',
    }
  },
    methods: {
    async createGamePin() {
      await createGamePin().then(response => {
        this.gamePin = response.gamePin
        this.gameId = response.gameId
        this.loadingGame = false
      }).catch(() => {})
    },
    async joinGame(){
      this.loadingJoin = true;
      if (localStorage.getItem('gameId') === null) {
        await setGamePin(this.gamePin, this.gameId);
      }
      const connection = await connect();
      connection.onopen = () => {
        this.loadingJoin = false;
        this.$router.push({name: 'PlayerName', params: {paramGameId: localStorage.getItem('gameId')}})
      }
    },
  },
  mounted(){
    if (!localStorage.getItem('gamePin')) {
      this.loadingGame = true;
      this.createGamePin()
    }
  }
}
</script>

<style>
</style>
