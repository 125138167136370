import {leaveGame, setLeaderboard} from "@/services/GameViewService";

export let currentConnection = undefined;
export let playerId = undefined;

export async function connect() {
    const newConnection = new WebSocket(`wss://${process.env.VUE_APP_WEBSOCKET_DOMAIN}/${process.env.VUE_APP_STAGE}`);
    currentConnection = newConnection;
    return newConnection
}

export async function disconnect(connection) {
    connection.close();
    connection.onclose = () => {};
    connection.onerror = async () => {
        if (localStorage.getItem('gameId')) {
            await leaveGame(localStorage.getItem('gameId'), true);
            this.$router.go(-3)
        }
    };
    currentConnection = '';
}

export async function sendMessage(payload) {
    currentConnection.send(JSON.stringify(payload));
}
export async function setPlayerId(id) {
    playerId = id
    localStorage.setItem('playerId', playerId);
}

export async function rejoin(gameId) {
    await sendMessage({action: 'rejoin', playerId});
    return await setLeaderboard(gameId);
}
