<template>
  <Transition @after-leave="onAfterLeave" name="SendSipModal">
    <div v-show="showSendSipModal" class="SendSipModal-mask" id="SendSipModal-mask">
      <div class="SendSipModal-header">
        <slot name="header">
          <div class='above'>
          </div>
          <div class='SendSipModalMessage'>
            <h2 class='SendSipModalTitle'>Send sip to...</h2>
            <pulse-loader :loading=loading :color=color :size=size :style="{'margin-top': '15%'}"></pulse-loader>
            <div v-show=!loading class="SendSipButtonContainer" id="SendSipButtonContainer"></div>
          </div>
          <div class='under'>
          </div>
        </slot>
      </div>
    </div>
  </Transition>
</template>

<script>
import {sendSipToPlayer} from "@/services/GameServices";
import {playerId} from "@/services/WebSocket";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      loading: false,
      color: '#ffffff',
      size: '15px'
    }
  },
  props: {
    showSendSipModal: Boolean,
    players: {
      type: Array
    }
  },
  methods: {
    onAfterLeave() {
      this.loading = false;
      document.getElementById("SendSipButtonContainer").innerText = '';
    },
    async closeModal(button) {
      this.loading = true;
      const playerName = this.players[this.players.findIndex(player => player.id === playerId)].name
      const response = await sendSipToPlayer(button.currentTarget.id, playerName);
      if (response === true) {
        this.$emit('close');
      }
    },
    setPlayers() {
      for (let i=0;i<this.players.length;i++) {
        if (this.players[i].id === playerId && this.players.length > 1) {
          continue;
        }
        let button = document.createElement("button");
        button.classList.add("SendSipModalButton");
        button.innerText = this.players[i].name;
        button.id = this.players[i].connectionId;
        button.addEventListener('click', this.closeModal);
        document.getElementById("SendSipButtonContainer").appendChild(button);
      }
    }
  },
  watch: {
    showSendSipModal() {
      if (this.showSendSipModal === true)
      {
        this.setPlayers();
      }
    }
  }
}
</script>

<style>
.SendSipModal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
}
.SendSipModalTitle {
  padding-top: 6%;
  color: white;
  margin: 0 0 0 0;
}
h3 {
  color: white;
}
h4 {
  color: white;
}
.SendSipModalMessage {
  background: #35654d;
  border-radius: 10px;
  border-style: solid;
  height: 40%;
  margin: 0 20px 0 20px;
  border-color: white;
  display: flex;
  flex-direction: column;
}
.SendSipButtonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
}
.SendSipModalButton {
  flex: 40px;
  min-width: 240px;
  font-size: 115%;
  background: #808080;
  color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 2%;
  margin-top: 2%;
  margin-bottom: 1%;
}
.SendSipModalButton:hover {
  background: rgb(109, 108, 108);
}
.SendSipModalButton__text {
  color: #ffffff;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>