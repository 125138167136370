<template>
  <Transition name="modal">
    <div v-if="showModal" class="modal-mask">
      <div class="modal-header">
        <slot name="header">
          <div class='above'>
          </div>
          <div v-if="!isLeave && !rejoin" class='modalMessage'>
            <h2>{{headline}}</h2>
            <h3>{{message}}</h3>
            <h4 v-if="subMessage?.length > 0">{{subMessage}}</h4>
            <button class="modalButton" @click="$emit('close')">
              <span class="modalButton__text">OK</span>
            </button>
          </div>
          <div v-if="isLeave && !rejoin" class='modalMessage'>
            <h2>Ooh Ooh</h2>
            <h3>{{leaveMessage}}</h3>
            <button class="modalButton" @click="$emit('close')">
              <span class="modalButton__text">OK</span>
            </button>
          </div>
          <div v-if="rejoin" class='modalMessage'>
            <h2>Rejoining...</h2>
            <pulse-loader :loading=showModal :color=color :size=size :style="{'margin-top': '15%'}"></pulse-loader>
          </div>
          <div class='under'>
          </div>
        </slot>
      </div>
    </div>
  </Transition>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      color: '#ffffff',
      size: '15px'
    }
  },
  props: {
    showModal: Boolean,
    isLeave: Boolean,
    leaveMessage: String,
    headline: String,
    message: String,
    subMessage: String,
    rejoin: Boolean,
  }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
}
.modalMessage {
  background: #35654d;
  border-radius: 10px;
  border-style: solid;
  height: 50%;
  margin: 0 20px 0 20px;
  padding: 0 5% 0 5%;
  border-color: white;
  display: grid;
  justify-content: center;
  align-content: center;
}
.modalMessage h2 {
  color: white;
  margin-bottom: 0;
}
h3 {
  color: white;
  margin-bottom: 0;
}
h4 {
  color: white;
  margin-bottom: 0;
}
.modalButton {
  font-size: 20px;
  background: #808080;
  max-height: 65px;
  margin-top: 10%;
}
.modalButton:hover {
  background: rgb(109, 108, 108);
}
.modalButton__text {
  color: #ffffff;
  transition: all 0.1s;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>