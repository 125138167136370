const axios = require("axios").default;
import {isCampen} from "@/services/GameViewService";

export async function setGamePin(gamePin, gameId) {
  try {
      localStorage.setItem('gamePin', gamePin);
      localStorage.setItem('gameId', gameId);
      return gamePin;
  } catch (error) {
      console.log(error)
      return false;
  }
}

export async function createGamePin() {
    let game = {};
    await axios.post(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_STAGE}/game`)
    .then(response => {
      game = response.data
    })
    .catch(() => {})
    return game;
}

export async function leaveGameHandler(playerId, gameId, hardLeave) {
    let statusCode = 0;
    let message = '';
    await axios.post(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_STAGE}/leave`, {
      playerId: playerId,
      gameId: gameId,
      hardLeave
    })
    .then(response => {
      statusCode = response.status;
      message = response.data.message;
    })
    .catch(error => {
      statusCode = error.response.status
      message = error.response.data.message
    })
    return {
        statusCode,
        message
    }
}

export async function findGame(gamePin) {
    let game = {}
    let statusCode = 0;
  await axios.get(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_STAGE}/game/${gamePin}`).then(
    response => {
      game = response.data;
      statusCode = 200;
    }
  ).catch(error => {
      game = undefined;
      statusCode = error.response.status;
  })
    return {
        statusCode,
        game
    };
}

export async function updateScoreHandler(gameId, score, connectionId, dices) {
  const data = {
      gameId: gameId,
      playedPlayerId: connectionId,
      score: score,
      isCampen: isCampen,
      dices,
  };
  let canHoldFirstDice = false;
  let canHoldSecondDice = false;
  await axios.post(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_STAGE}/game/score`, data)
      .then(response => {
          canHoldFirstDice = response.data.holdDicesInfo.canHoldFirstDice;
          canHoldSecondDice = response.data.holdDicesInfo.canHoldSecondDice;
      });
  return {
      canHoldFirstDice,
      canHoldSecondDice,
  }
}

export async function sendSipToPlayer(playerConnectionId, playerName) {
    let response;
    const data = {
        playerConnectionId,
        playerName
    }
    await axios.post(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_STAGE}/sendSip`, data)
        .then(() => {response = true})
        .catch(() => {response = false});
    return response
}

export async function getLeaderboard(gameId) {
    let leaderboard = []
    let statusCode = 0;
    await axios.get(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_STAGE}/leaderboard/${gameId}`).then(
        response => {
            leaderboard = response.data;
            statusCode = 200;
        }
    ).catch(error => {
        leaderboard = undefined;
        statusCode = error.response.status;
    })
    return {
        statusCode,
        leaderboard
    };
}
